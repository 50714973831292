<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>
		<div class="justify-space-between">
			<div
				class="flex-1 mr-10 bg-white pa-10"
			>
				<div>
					<h6 class="text-left color-identify under-line-identify">기본정보</h6>
					<table
						class="table th-left td-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>ID</th>
							<td>{{ item.roleId }}</td>
							<th>비밀번호</th>
							<td class="text-right">
								<button
									class="pa-5-10 bg-identify"
									@click="isPassword"
								>비밀번호 변경</button>
							</td>
						</tr>
						<tr>
							<th>담당자명</th>
							<td>{{ item.name }}</td>
							<th>연락처</th>
							<td>{{ item.hp }}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">총판 계약 수수료</h6>
					<table
						class="table th-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>월세 수수료</th>
							<td>
								<div class="justify-space-between">
									{{ item.monthlyFee }}%
									<button
										class="pa-5-10 bg-identify"
										@click="isUpdateFee('0')"
									>수수료 변경</button>
								</div>
							</td>
							<th>배달비 수수료</th>
							<td>
								<div class="justify-space-between">
									{{ item.quickFee }}%
									<button
										class="pa-5-10 bg-identify"
										@click="isUpdateFee('1')"
									>수수료 변경</button>
								</div>
							</td>
						</tr>
						<tr>
							<th>PG +1 수수료</th>
							<td>
								<div class="justify-space-between">
									{{ item.d1Fee }}%
									<button
										class="pa-5-10 bg-identify"
										@click="isUpdateFee('2')"
									>수수료 변경</button>
								</div>
							</td>
							<th>PG +5 수수료</th>
							<td>
								<div class="justify-space-between">
									{{ item.d5Fee }}%
									<button
										class="pa-5-10 bg-identify"
										@click="isUpdateFee('3')"
									>수수료 변경</button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>


				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">관리자 </h6>
					<table
						class="table th-left td-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr
							v-for="(role, index) in item.paynaraManagerList"
							:key="'role_' + index"
						>
							<th>{{ role.roleName }}</th>
							<td>
								<div class="justify-space-between">
									{{ role.name }}

									<button
										v-if="role.roleId != 'master'"
										class="bg-identify pa-5-10"
										@click="isUpdate(role)"
									>사용 정지</button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="flex-1 bg-white pa-10">
				<h6 class="color-identify">수정 이력</h6>
				<ul
					class="top-line-identify"
				>
					<li
						v-for="(history, index) in item_history"
						:key="'history_' + index"
						class="justify-space-between pa-10"
					>
						<div class="mr-10">{{ history.date}}</div>
						<div class="flex-1">{{ history.content }}</div>
					</li>
				</ul>
			</div>
		</div>

		<Modal
			:is_modal="modal_update"
			:top="true"
			:bottom="true"

			title="수수료 변경"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="doUpdateFee"
			@cancel="close"

		>
			<div slot="modal-content">
				<table
					class="table th-left"
				>
					<col width="120px" />
					<col width="auto" />
					<tbody>
						<tr>
							<th>{{ type.name }}</th>
							<td><input v-model="type.value" placeholder="수수료를 입력하세요"/></td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>

		<Modal
			:is_modal="modal_role"
			:top="true"
			:bottom="true"

			title="계정 사용정지"
			:content="role.name + ' 계정을 사용정지 하시겠습니까?'"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="doUpdate"
			@cancel="close"

		></Modal>

		<Modal
			:is_modal="modal_fee"
			:top="true"
			:bottom="true"

			title="수수료 변경"
			:content="type.name + ' 수수료를 변경하시겠습니까?'"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="toUpdateFee"
			@cancel="close"

		></Modal>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="비밀번호 변경"
			width="380px"
			content_class="pa-30-10"

			@close="close"
			@click="save"
			@cancel="close"
		>
			<Password
				v-if="is_modal"
				:item="item"
				slot="modal-content"

				@success="success"
			></Password>

		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import Password from "@/view/Management/Password";
export default {
	name: 'MypageMaster'
	,components: {Password, Modal}
	,props: ['Axios', 'codes', 'user']
	,data: function(){
		return {
			program: {
				name: '마이페이지'
				, top: true
			}
			, item: {
				id: '아이디'
			}
			,role: {

			}
			,type: {
				name: ''
				,code: ''
				,value: ''
			}
			, items_history: []
			,is_modal: false
			,modal_option: {

			}
			,modal_role: false
			,modal_fee: false
			,modal_update: false
		}
	}
	,computed: {
		item_history: function(){
			return this.items_history.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'mypage/headquarter'
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,save: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: 'mypage/changePw'
					,data: this.item
				})

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.close()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isPassword: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
			this.modal_role = false
			this.modal_fee = false
			this.modal_update = false

			this.item.originPw = ''
			this.item.changePw = ''
			this.item.changePwConfirm = ''
		}
		,isUpdate: function(role){
			this.role = role
			this.modal_role = true
		}
		,doUpdate: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'mypage/suspend/' + this.role.idx
				})

				if(result.success){
					await  this.getData()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
				this.close()
			}
		}
		,doUpdateFee: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: 'mypage/changeFee'
					,data: {
						feeRate: this.type.value
						,feeType: this.type.code
					}
				})

				if(result.success){
					await  this.getData()
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.close()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isUpdateFee: function(type){

			this.type.code = type
			if(type == '0'){
				this.type.name = '월세'
			}else if(type == '1'){
				this.type.name = '배달비'
			}else if(type == '2') {
				this.type.name = 'D+1 정산'
			}else if(type == '3') {
				this.type.name = 'D+5 정산'
			}
			this.modal_fee = true
		}
		,success: function(){
			this.is_modal = false
		}
		,toUpdateFee: function(){
			this.close()
			this.modal_update = true
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>














