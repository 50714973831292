<template>
	<div
		class="full-height"
	>
		<MypageMaster
			v-if="user.roleGroup == 'admin'"

			:Axios="Axios"
			:codes="codes"
			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageMaster>

		<MypageAgency
			v-else-if="user.roleGroup == 'agency'"

			:Axios="Axios"
			:codes="codes"
			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageAgency>

		<MypageMerchant
			v-else-if="user.roleGroup == 'merchant'"

			:Axios="Axios"
			:codes="codes"
			:user="user"

			@setNotify="setNotify"
			@onLoad="onLoad"
		></MypageMerchant>
	</div>
</template>

<script>
import MypageMaster from "@/view/Management/MypageMaster";
import MypageAgency from "@/view/Management/MypageAgency";
import MypageMerchant from "@/view/Management/MypageMerchant";

export default {
	name: 'Mypage'
	,components: {MypageMerchant, MypageAgency, MypageMaster}
	,props: ['Axios', 'codes', 'user']
	,data: function(){
		return {
			program: {
				name: '마이페이지'
				, top: true
			}
		}
	}
	,computed: {
		item_history: function(){
			return this.items_history.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		setNotify: function({ type, message }){
			this.$emit('setNotify', { type: type, message: message })
		}
		,onLoad: function(program){
			this.$emit('onLoad', program)
		}
	}
	,created() {

	}
}
</script>














