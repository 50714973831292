<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>
		<div class="justify-space-between">
			<div
				class="flex-1 mr-10 bg-white pa-10"
			>
				<div>
					<h6 class="text-left color-identify under-line-identify">기본정보</h6>
					<table
						class="table th-left td-right"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>ID</th>
							<td>{{ item.id }}</td>
							<th>비밀번호</th>
							<td class="text-right">
								<button
									class="pa-5-10 bg-identify"
									@click="isPassword"
								>비밀번호 변경</button>
							</td>
						</tr>
						<tr>
							<th>대표 연락처</th>
							<td>{{ item.hp }}</td>
							<th></th>
							<td></td>
						</tr>
						</tbody>
					</table>
				</div>

				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">사업자 정보</h6>
					<table
						class="table th-left td-right"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>상호명(법인명)</th>
							<td>{{ item.shopName }}</td>
							<th>대표자 성명</th>
							<td>{{ item.name }}</td>
						</tr>
						<tr>
							<th>사업자 등록번호</th>
							<td>{{ item.business }}</td>
							<th>법인 등록번호</th>
							<td>{{ item.corporation }}</td>
						</tr>
						<tr>
							<th>업종</th>
							<td>{{ item.businessLine }}</td>
							<th>업태</th>
							<td>{{ item.businessCondition }}</td>
						</tr>
						<tr>
							<th>사업장 주소</th>
							<td colspan="3">{{ item.address }}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">서비스 수수료</h6>
					<table
						class="table th-left"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>서비스 수수료</th>
							<td>{{ item.serviceFee}}</td>
							<th>특별 우대이율</th>
							<td>{{ item.preferentialRate}}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div
					class="mt-10"
				>
					<h6 class="text-left color-identify under-line-identify">정산 정보</h6>
					<table
						class="table th-left td-right"
					>
						<col width="160px"/>
						<col width="auto" />
						<col width="160px"/>
						<col width="auto" />
						<tbody>
						<tr>
							<th>정산주기</th>
							<td>{{ item.calCycle }}</td>
							<th>정산은행</th>
							<td>{{ item.calBankName}}</td>
						</tr>
						<tr>
							<th>계좌번호</th>
							<td>{{ item.calAccount}}</td>
							<th>예금주명</th>
							<td>{{ item.calAccountHolder }}</td>
						</tr>

						<tr>
							<th>이메일</th>
							<td>{{ item.email}}</td>
							<th></th>
							<td></td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="flex-1 bg-white pa-10">
				<h6 class="color-identify">수정 이력</h6>
				<ul
					class="top-line-identify"
				>
					<li
						v-for="(history, index) in item_history"
						:key="'history_' + index"
						class="justify-space-between pa-10"
					>
						<div class="mr-10">{{ history.date}}</div>
						<div class="flex-1">{{ history.content }}</div>
					</li>
				</ul>
			</div>
		</div>

		<Modal
			:is_modal="modal_role"
			:top="true"
			:bottom="true"

			title="계정 사용정지"
			:content="role.name + ' 계정을 사용정지 하시겠습니까?'"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="doUpdate"
			@cancel="close"

		></Modal>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="비밀번호 변경"
			width="360px"
			content_class="pa-30-10"

			@close="close"
			@click="save"
			@cancel="close"
		>
			<Password
				v-if="is_modal"
				:item="item"
				slot="modal-content"

				@success="success"
			></Password>

		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import Password from "@/view/Management/Password";
export default {
	name: 'MypageAgency'
	,components: {Password, Modal}
	,props: ['Axios', 'codes', 'user']
	,data: function(){
		return {
			program: {
				name: '마이페이지'
				, top: true
			}
			, item: {
				id: '아이디'
			}
			,role: {

			}
			,type: {

			}
			, items_history: []
			,is_modal: false
			,modal_option: {

			}
			,modal_role: false
			,modal_fee: false
		}
	}
	,computed: {
		item_history: function(){
			return this.items_history.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'mypage/admin'
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,save: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					,url: 'mypage/changePw'
					,data: this.item
				})

				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.close()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isPassword: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
			this.modal_role = false
			this.item.originPw = ''
			this.item.changePw = ''
			this.item.changePwConfirm = ''
		}
		,isUpdate: function(role){
			this.role = role
			this.modal_role = true
		}
		,doUpdate: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'mypage/suspend/' + this.role.idx
				})

				if(result.success){
					await  this.getData()
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
				this.close()
			}
		}
		,success: function(){
			this.is_modal = false
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>














