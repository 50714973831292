<template>
	<div>
		<table class="table th-left">
			<col width="120px" />
			<col width="auto" />
			<tbody>
				<tr>
					<th>현재 비밀번호 </th>
					<td><input type="password" v-model="item.originPw" max="50" placeholder="현재 비밀번호를 입력하세요"/> </td>
				</tr>
				<tr>
					<th>변경 비밀번호 </th>
					<td><input type="password" v-model="item.changePw" max="50" placeholder="비밀번호를 입력하세요"/> </td>
				</tr>
				<tr>
					<th>비밀번호 확인</th>
					<td><input type="password" v-model="item.changePwConfirm" max="50" placeholder="비밀번호를 한번더 입력하세요"/> </td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'Password'
		, props: ['item']
		, data: function(){
			return {

			}
		}
		,methods: {
			save: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'posts'
						,url: 'mypage/changePw'
						,data: this.item
					})

					if(result.success){
						this.$emit('setNotify', { type: 'error', message: result.message})
						this.$emit('success')
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
		}
	}
</script>